<template>
  <div class="domain-options">
    <div
      v-if="domainSearchAvailable"
      class="available-domain-container"
    >
      <div class="inform-text">
        <span>
          {{ text.availableDomain }}
        </span>
      </div>
      <div class="available-domain">
        <span :class="setOptionAsSelected">
          {{ domainSearch }}
        </span>
        <b-button
          class="domain-button"
          :variant="applyButtonVariant"
          aria-label="Select Domain Button"
          @click="handleDomainSelect(domainSearch)"
        >
          {{ selectedDomain === domainSearch ? 'Selected' : 'Available' }}
        </b-button>
      </div>
    </div>
    <div class="inform-text">
      <span>
        {{ text.options }}
      </span>
    </div>
    <ct-table
      :class="'domain-table ' + displayDomainTable"
      :items="alternativeOptions"
      :fields="tableFields"
      :is-paginated="false"
      :small="false"
      :stacked="false"
      :loaded="domainSearchResults !== null"
      :hide-headers="true"
    >
      <template
        #cell(domain)="{ item }"
      >
        <span
          :class="(selectedDomain && selectedDomain !== item.domain) ? 'non-selected-domain' : 'selected-domain'"
        >
          {{ item.domain }}
        </span>
      </template>
      <template
        #cell(select_button)="{ item }"
        :class="selectedDomain === item.domain ? 'selected-row' : ''"
      >
        <b-button
          class="domain-button"
          :variant="selectedDomain === item.domain ? 'primary' : 'outline-primary'"
          aria-label="Select Domain Button"
          @click="handleDomainSelect(item.domain)"
        >
          {{ selectedDomain === item.domain ? 'Selected' : 'Available' }}
        </b-button>
      </template>
    </ct-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DomainOptions',
  components: {
    CtTable: () => import('@/components/shared/CtTable.vue'),
  },
  props: {
    bus: {
      type: Object,
      required: true,
    },
    domainSuggestions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      domainIsAvailable: false,
      alternativeOptions: [],
      text: {
        availableDomain: 'Your domain is available',
        options: 'Browse through additional options',
      },
      tableFields: [
        { key: 'domain', label: '', sortable: false, class: 'domain-cell' },
        { key: 'select_button', label: '', class: 'select-button-cell' },
      ],
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domainSearch',
      'domainSearchResults',
      'domainSearchAvailable',
      'selectedDomain',
    ]),
    displayDomainTable() {
      return this.domainSearch === null ? ' d-none' : ''
    },
    setOptionAsSelected() {
      return (this.selectedDomain && this.selectedDomain !== this.domainSearch) ?
        'non-selected-domain' :
        'selected-domain'
    },
    applyButtonVariant() {
      return this.selectedDomain === this.domainSearch  ?
        'primary' :
        'outline-primary'
    },
  },
  mounted() {
    this.filterOutAvailableDomain()
  },
  methods: {
    ...mapActions('domains', [
      'setSelectedDomain',
    ]),
    filterOutAvailableDomain() {
      this.domainSearchResults.forEach(value => {
        if(value?.available && value?.domain === this.domainSearch) return

        this.alternativeOptions.push(value)
        if(this.domainSuggestions?.length) {
          this.domainSuggestions.slice(1).forEach(suggestion => {
            if(!this.alternativeOptions.some(option => option.domain === suggestion)) {
              this.alternativeOptions.push({ domain: suggestion, available: true })
            }
          })
        }
      })
    },
    handleDomainSelect(item) {
      this.setSelectedDomain(item)
      this.bus.$emit('domain-selected', true)
    },
  },
}
</script>

<style lang="scss" scoped>
// palette
$selected-text-color: #2FB2AE;

.domain-options {

  .available-domain-container {
    margin-bottom: 3em;

    .available-domain {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1em 1.05em;
    }
  }

  .inform-text {
    font-weight: $ct-ui-font-weight-5;
    font-size: 1.5em;
    margin-bottom: 1em;
  }

  .domain-button {
    text-align: center;
  }

  .non-selected-domain {
    opacity: 0.5;
  }

  .selected-domain {
    color: $selected-text-color;
  }
}

</style>,

<style lang="scss">

.domain-options {

  .domain-table {

    .b-table-sticky-header {
      max-height: 300px !important;
    }

    .expandable-row {
      padding-left: 0 !important;
    }

    tr {
      cursor: initial !important;
    }

    td {
      display: table-cell !important;

      &.domain-cell {
        text-align: left;
        vertical-align: middle;
      }

      &.select-button-cell {
        text-align: right;
        vertical-align: middle;
        padding-right: calc(0.5rem + 0.55em) !important;
        padding-top: 1em !important;
        padding-bottom: 1em !important;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .domain-table {
    tr {
      display: flex;
      flex-direction: column;

      .select-button-cell {
        .expandable-row {
          .domain-button {
            width: 100%;
          }
        }
      }

      td:nth-child(even) {
        border: none;
      }
    }
  }
}
</style>
